<template>
    <div class="p-component p-d-flex range-date">
        <div class="double-input-container">
            <Calendar class="double-input" :placeholder="$t('from')" v-model="modelMin" :monthNavigator="true" :yearNavigator="true" :showTime="true" yearRange="2000:2030" />
        </div>
        <div class="double-input-container">
            <Calendar class="double-input" :placeholder="$t('to')" v-model="modelMax" :monthNavigator="true" :yearNavigator="true" :showTime="true" yearRange="2000:2030" />
        </div>
    </div>
</template>

<script>

import { computed } from "vue";
import Calendar from 'primevue/calendar';

/*
*  @group Widgets
*  RangeDate is used to generate a field that as 2 field inside to select a range. Used in `ModelForm` in searchmode
*/
export default {
    name: "RangeDate",
    props: {
        // `v-model` attribute
        modelValue : {
            // `{min: String, max: String}`
            type: Object,
            required: false,
        }
    },
    emits: ['update:modelValue'],
    components: {
        Calendar,
    },
    setup(props, { emit }) {
        // computed value used to pass min and max in an object
        const modelMin = computed({
            get: () => {return props.modelValue?.min ? props.modelValue.min : undefined;},
            set: (value) => {emit('update:modelValue', {min:value, max:modelMax.value});}
        })
        const modelMax = computed({
            get: () => {return props.modelValue?.max ? props.modelValue.max : undefined;},
            set: (value) => emit('update:modelValue', {min:modelMin.value, max:value})
        })
        return {
            modelMin,
            modelMax,
        }
    }
}
</script>

<style scoped>
.double-input-container {
    width: 50%;
}
.double-input {
    width: 100%;
}
</style>
