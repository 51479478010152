<template>
    <div class="sidebar">
        <div class="logo"><img alt="logo" src="@/assets/theme/images/logo.png"></div>
        <div class="sidebar-menu">
            <div class="menu-category">Navigation</div>
            <div class="menu-items">
                <router-link :to="item.to" v-for="item in menu" :key="item.name">
                    <i :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

// @group Layouts
// SideBar used in all pages
export default {
    name: "Sidebar",
    setup() {
        const i18n = useI18n();
        const store = useStore();
        const is_shopping = store.getters["schema/isShopping"];
        let menu = [
            {
                to: { name: 'home' },
                icon: 'fal fa-tv',
                title: i18n.t('dashboard')
            },
            {
                to: { name: 'transaction-list' },
                icon: 'fal fa-euro-sign',
                title: i18n.t('transactions')
            }
        ]

        if (is_shopping) {
            menu.push({
                to: { name: 'cart-list' },
                icon: 'fal fa-shopping-basket',
                title: i18n.t('carts')
            })
        }

        menu.push({
            to: { name: 'pos-list' },
            icon: 'fal fa-tv',
            title: i18n.t('Pos')

        });

        menu.push({
            to: { name: 'map', params: { config: 'default' } },
            name: 'map',
            icon: 'fal fa-map-marked-alt',
            title: i18n.t('map')
        });

        return {
            menu,
        }
    }
}
</script>

<style lang="scss">
@import "src/assets/theme/scss/variable";

.sidebar, .sidebar .logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
 .sidebar {
     position: fixed;
     left: 0;
     top: 0;
     height: 100%;
     background-color: var(--surface-a);
     width: 250px;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     -webkit-transition: -webkit-transform .4s cubic-bezier(.05,.74,.2,.99);
     transition: -webkit-transform .4s cubic-bezier(.05,.74,.2,.99);
     transition: transform .4s cubic-bezier(.05,.74,.2,.99),-webkit-transform .4s cubic-bezier(.05,.74,.2,.99);
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     z-index: 1010;
 }
.sidebar .logo {
    min-height: 70px;
    background-color: $topbar-color;
    border-bottom: 1px solid $topbar-color;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.sidebar .logo img {
    width: 180px;
}
.sidebar .sidebar-menu {
    padding: 0 1rem;
    overflow-y: auto;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    border-right: 1px solid var(--surface-d);
}
.sidebar .sidebar-menu .menu-category {
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 1.5rem 0 1rem 0;
    text-transform: uppercase;
    font-size: .6875rem;
    color: #6e768e;
    font-weight: 600;
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
}
.sidebar .sidebar-menu .menu-items {
    padding: 0 0 1rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.sidebar .sidebar-menu .menu-items a {
    color: var(--text-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem;
    border-radius: 3px;
    line-height: 18px;
    cursor: pointer;
}
.sidebar .sidebar-menu .menu-items a i {
    margin-right: 10px;
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
    width: 20px;
    line-height: 1.0625rem;
}
.sidebar .sidebar-menu .menu-items a:hover, .router-link-active {
    color: $sidebar-item-color !important;
}
@media screen and (max-width: 960px) {
    .sidebar {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        z-index: 1008;
    }
    .sidebar.sidebar-active {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
</style>
