<template>
    <div class="model-form" data-cy="model-form">
        <div v-for="fieldset in orderedFieldset" :key="fieldset.fields" class="p-grid p-mb-2" data-cy="model-form-section">
            <div v-if="fieldset.title" class="p-col-12 header-title p-mb-2 p-mt-3 ">
                <h3 class="header-title p-mb-0 border-bottom p-mt-0">{{ fieldset.title }}</h3>
            </div>
            <div class="p-col-12 p-md-6 p-lg-4 p-xl-4 p-xxl-3" data-cy="schema-field"
                 v-for="field_id in fieldset.fields" :key="field_id">
                <div class="p-field">
                    <label :for="field_id">{{ fields[field_id].title }}</label>
                    <SchemaField
                        :field="fields[field_id]"
                        :searchmode="searchmode"
                        v-model="formValues[field_id]"
                    ></SchemaField>
                </div>
            </div>
        </div>

        <div v-if="actions" class="p-mt-2 p-d-flow-root">
            <SchemaAction v-for="action in actions" :key="action" :model="model" :action="action" @action="emit('action', $event)"></SchemaAction>
        </div>

    </div>
</template>

<script>

import { useStore } from "vuex";
import SchemaField from "@/components/widgets/SchemaField";
import SchemaAction from "@/components/widgets/SchemaAction";
import { computed } from "vue";

/* @group Widgets
*  ModelForm is used to generate form based on Schema structure.
*  In SearchMode, the form is intended to be used to perform search,
*  and return fields values named as Django filters (ex `age__gte`, `name_icontains`).
*/
export default {
  name: "ModelForm",
  props: {
      // Contains the set of field to be displayed by this ModelForm
      "fieldset": {
          type: Object,
          required: true,
      },
      // Define the current model name for this ModelForm
      "model": {
          type: String,
          required: true,
      },
      // A two way Object that contains default values for each fields of this model form,
      // Modified fields values will be returned in this Object
      "values": {
          type: Object,
          required: false,
          default: new Object,
      },
      // Define whether or not this ModelForm should be displayed in order to perfom search, or edition
      "searchmode": {
          type: Boolean,
          required: false,
          default: false,
      },
      // Define actions buttons displayed at the bottom of the ModelForm
      // At each button press, an event is emmited throught the `@action` event, containing the name of the action passed in this Array
      "actions": {
          type: Array,
          required: false,
          default: new Array,
      },
  },
  emits: ["action"],
  components: {
    SchemaField,
    SchemaAction
  },
  setup(props, { emit }) {
      const store = useStore();
      const fields = store.getters['schema/getFields'](props.model);

      const formValues = computed({
          get: () => props.values ? props.values : {},
          set: (value) => emit('update:values', value)
      });

      const orderedFieldset = computed({
          get: () => {
              let v = props.fieldset; // copy to not affect fieldset
              // used to sort fieldset bey their weight
              v.sort(function (a, b) {
                  return a.weight > b.weight ? 1 : -1
              })
              return v;
          },
      });

      return {
          fields,
          formValues,
          orderedFieldset,
          emit,
      };
  },
};
</script>
<style scoped>
.border-bottom {
    border-bottom: solid 0.5px #DDD;
}
</style>
