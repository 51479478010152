<template>
    <div class="p-component p-d-flex range-integer">
        <div class="double-input-container">
            <InputNumber class="double-input" :placeholder="$t('min')" mode="decimal" :maxFractionDigits="2"  v-model="modelMin" prefix="Min " :locale="i18n.locale.value"/>
        </div>
        <div class="double-input-container">
            <InputNumber class="double-input" :placeholder="$t('max')" mode="decimal" :maxFractionDigits="2" v-model="modelMax" prefix="Max " :locale="i18n.locale.value"/>

        </div>
    </div>
</template>

<script>

import { computed } from "vue";
import InputNumber from 'primevue/inputnumber';
import {useI18n} from "vue-i18n";
/*
*  @group Widgets
*  RangeInteger is used to generate a field that as 2 field inside to select a range. Used in `ModelForm` in searchmode
*/
export default {
    name: "RangeInteger",
    props: {
        // `v-model` attribute
        modelValue : {
            // `{min: Number, max: Number}`
            type: Object,
            required: false,
        }
    },
    emits: ['update:modelValue'],
    components: {
        InputNumber,
    },
    setup(props, { emit }) {
        const i18n = useI18n();
        // computed value used to pass min and max in an object
        const modelMin = computed({
            get: () => props.modelValue?.min,
            set: (value) => emit('update:modelValue', {min:value, max:modelMax.value})
        })

        const modelMax = computed({
            get: () => props.modelValue?.max,
            set: (value) => emit('update:modelValue', {min:modelMin.value, max:value})
        })
        return {
            modelMin,
            modelMax,
            i18n,
        }
    }
}
</script>

<style scoped>
.double-input-container {
    width: 50%;
}
.double-input {
    width: 100%;
}
</style>
