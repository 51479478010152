<template>
    <div class="list-view" data-cy="list-view" ref="scrollComponent">
        <div class="p-card p-mb-3">
            <div class="p-card-body">
                <Message v-for="msg of messages" :severity="msg.severity" :closable="true" :key="msg.content">{{msg.content}}</Message>
                <ModelForm :model="model" :searchmode="true" :fieldset="searchFieldset" :values="form.values" :actions="actions" @action="handleAction($event)" />
            </div>
        </div>
        <div class="p-card animate__animated animate__fadeIn">
            <div class="p-card-body">
                <Table :model="model" :dataList="tableData" :listDisplay="listDisplay" :order="order" @sort="sortData($event)" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router'

import ModelForm from "@/components/widgets/ModelForm";
import Table from "@/components/widgets/Table";
import {useI18n} from "vue-i18n";
import Message from 'primevue/message';
import { showError } from "@/mixins/message";

/*
*  @group Layouts
*  ListView is the base view used to display list of objects and a searchform
*/
export default {
    name: "ListView",
    props: {
        // The name of model to list
        'model' : {
            type: String,
            required: true,
        }
    },
    components: {
        ModelForm,
        Table,
        Message
    },
    setup(props) {
        const i18n = useI18n();
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        let next = null;
        const loading = ref(false);
        const tableData = ref([]);
        const order = ref(null);
        const messages = ref([]);
        const scrollComponent = ref(null);
        const exportURL = store.getters["schema/getExportUrl"](props.model);
        const searchFieldset = store.getters["schema/getSearchFieldsets"](props.model);
        const listDisplay = store.getters['schema/getListDisplay'](props.model);
        const actions = store.getters["schema/getSearchActions"](props.model);
        const fields = store.getters['schema/getFields'](props.model);
        const form = reactive({
            values: valuesFromQueryParams(route.query)
        });

        function getData() {
            loading.value = true;
            let payload;
            let filter = prepareFilters();
            payload = {
                model: props.model,
                page: 1,
                order: order.value,
                filter: filter
            }
            payload.modelUrl = store.getters["schema/getApiUrl"](props.model);
            store.dispatch('data/getModelList', payload).then(
                (response) => {
                    loading.value = false;
                    next = response.data.next;
                    tableData.value = response.data.results;
                },
                (error) => {
                    loading.value = false;
                    showError(error, messages, i18n);
                }
            );
        }

        function prepareFilters() {
            // prepare filter by renaming field names to django query filter name
            let filters = {};
            for (let k in form.values) {
                if (form.values[k] === undefined) {
                    continue;
                }
                if (fields[k]?.type === 'text') {
                    filters[k + '__icontains'] = form.values[k].trim();
                }
                else if (fields[k]?.type === 'fk') {
                    if (form.values[k].id != null) {
                        filters[k] = form.values[k].id;
                    }
                }
                else if (fields[k]?.type === 'choices') {
                    if (form.values[k].id != null) {
                        filters[k] = form.values[k].id;
                    }
                }
                else if (fields[k]?.type === 'number') {
                    if (form.values[k].min != null) {
                        filters[k + '__gte'] = form.values[k].min;
                    }
                    if (form.values[k]?.max != null){
                        filters[k + '__lte'] = form.values[k].max;
                    }
                }
                else if (fields[k]?.type === 'datetime') {
                    if (form.values[k].min != null) {
                        filters[k + '__gte'] = new Date(form.values[k].min).toJSON();
                    }
                    if (form.values[k]?.max != null){
                        filters[k + '__lte'] = new Date(form.values[k].max).toJSON();
                    }
                }
                else {
                    filters[k] = form.values[k];
                }
            }
            return filters;
        }

        function toFieldType(value, fieldType) {
            let v;
            switch (fieldType) {
                case 'datetime':
                    v = new Date(value);
                    break;
                case 'number':
                    v = parseFloat(value);
                    break;
                case 'choices':
                    v = {id: parseInt(value), text:""};
                    break;
                case 'fk':
                    v = {id: parseInt(value), text:""};
                    break;
                default:
                    v = value
            }
            return v
        }

        function valuesFromQueryParams(params) {
            let values = {};
            let queryext = {_gte: 'min', _lte: 'max'};
            for (let k in params) {
                if (k === 'order_by') {
                    order.value = params[k];
                }
                else if (k in fields & !['datetime', 'number'].includes(fields[k]?.type)) {
                    values[k] = toFieldType(params[k], fields[k].type)
                }
                else for (let ext in queryext) {
                    let knoext = k.replace('_' + ext, '');
                    if (k.endsWith('_' + ext) & (knoext in fields & ['datetime', 'number'].includes(fields[knoext]?.type))) {
                        let v = toFieldType(params[k], fields[knoext].type);

                        if (!(knoext in values)) {
                            values[knoext] = {}
                        }
                        values[knoext][queryext[ext]] = v;
                    }
                }
            }
            return values
        }

        function handleAction(action) {
            // handle action in event @action emitted by ModelForm, based on event.name
            switch (action.name) {
                case 'search':
                    getData();
                    router.push({name:route.name, query: prepareFilters()});
                    break;
                case 'export':
                    dataExport(action.value);
                    break;
                case 'reset':
                    order.value = null;
                    form.values = {};
                    router.push({name:route.name, query: {}});
                    getData();
                    break;
            }
        }

        function sortData(new_order) {
            order.value = new_order;
            getData();
            router.push({name:route.name, query: {...route.query, order_by:order.value}});
        }

        function dataExport(format) {
            loading.value = true;
            let filter = prepareFilters();
            store.dispatch('data/getModelExport', {url: exportURL, filter: filter, format: format, order: order.value}).then(
                (response) => {
                    loading.value = false;

                    let now = new Date();
                    let filename = 'export-' + now.toISOString() +  '.' + format;
                    let blobContent = format === 'json' ? JSON.stringify(response.data) : response.data;
                    let blob = new Blob([blobContent], {type: response.headers['content-type']});

                    // check IE
                    let ua = window.navigator.userAgent;
                    let msie = ua.indexOf('MSIE ');
                    let trident = ua.indexOf('Trident/');
                    let isIE = (msie > 0 || trident > 0);

                    if (window.navigator.userAgent.indexOf("Edge") > -1 || isIE ) {
                        window.navigator.msSaveOrOpenBlob(blob, filename);
                    } else {
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = filename;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                },
                (error) => {
                    loading.value = false;
                    showError(error, messages, i18n);
                }
            );
        }


        function handleScroll() {
            // trigger load of next api page of list when user scroll near to the end
            // of the page
            let element = scrollComponent.value;
			if ( (element.getBoundingClientRect().bottom) < window.innerHeight * 1.5 && loading.value === false ) {
                if (next) {
                    loading.value = true;
                    store.dispatch('data/getData', {url: next}).then(
                        (response) => {
                            loading.value = false;
                            next = response.data.next;
                            tableData.value.push(...response.data.results);
                        },
                        (error) => {
                            loading.value = false;
                            showError(error, messages, i18n);
                        }
                    );
                }
			}
        }

        onMounted(() => {
            getData();
			window.addEventListener("scroll", handleScroll);
		})

		onUnmounted(() => {
			window.removeEventListener("scroll", handleScroll);
		})

        return {
            actions,
            form,
            order,
            searchFieldset,
            tableData,
            scrollComponent,
            listDisplay,
            handleAction,
            sortData,
            messages
        }
    }
}
</script>
